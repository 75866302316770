<template>
  <div>
    <b-form-row>
      <b-col sm="12" md="10" lg="10" xl="8">
        <b-form-group>
          <h4 class="page-sub-header">
            News categories
            <InheritText v-if="multilingualToggle" />
          </h4>
          <b-form-row>
            <b-col cols="12" sm="4" v-for="(option, index) in data.categories" :key="index">
              <b-form-checkbox
                :checked="option.isSelected"
                :disabled="multilingualToggle"
                :v-modal="option.isSelected"
                @input="option.isSelected = $event"
                :id="'news-category-' + index"
                :name="'news-category-' + index"
                inline
                >{{ option.categoryName }}</b-form-checkbox
              >
            </b-col>
          </b-form-row>
          <b-form-invalid-feedback class="d-block" v-if="validateNewsCategories() && formSubmitted">Atleast one category required.</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <b-form-select
              id="dashboard-items"
              class="form-control"
              :options="widgetDashboardCountList"
              v-model.number="data.dashboardItemCnt"
              @input="data.dashboardItemCnt = $event"
              :class="{
                'is-value-exist': data.dashboardItemCnt != null,
                'is-invalid': !data.dashboardItemCnt && formSubmitted,
              }"
              :disabled="multilingualToggle"
            >
            </b-form-select>
            <label for="dashboard-items"
              >Dashboard items
              <InheritText v-if="multilingualToggle" />
            </label>
            <b-form-invalid-feedback class="d-block" v-if="!data.dashboardItemCnt && formSubmitted">Dashboard items required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" class="pt-md-2 mt-md-1"> Recommended: 3 entries</b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <b-form-select
              id="dashboard-expanded-view-items"
              class="form-control"
              :options="widgetDashboardCountList"
              v-model.number="data.expViewItemCnt"
              @input="data.expViewItemCnt = $event"
              :class="{
                'is-value-exist': data.expViewItemCnt != null,
                'is-invalid': !data.expViewItemCnt && formSubmitted,
              }"
              :disabled="multilingualToggle"
            >
            </b-form-select>
            <label for="dashboard-expanded-view-items"
              >Expanded view items
              <InheritText v-if="multilingualToggle" />
            </label>
            <b-form-invalid-feedback class="d-block" v-if="!data.expViewItemCnt && formSubmitted">Expanded view items required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" class="pt-md-2 mt-md-1"> Recommended: 5 entries</b-col>
    </b-form-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { WidgetDashboardCountList } from '../../../utilities/constants'
export default {
  name: 'NewsWidget',
  props: ['data', 'tenantId', 'culture', 'defaultCulture', 'formSubmitted', 'isEditPage'],
  data() {
    return {
      widgetDashboardCountList: WidgetDashboardCountList,
    }
  },
  computed: {
    multilingualToggle() {
      return this.isMultilingualSupportEnabled && this.isDefaultCulture
    },
    isDefaultCulture() {
      return this.culture !== this.defaultCulture
    },
    ...mapState({
      userRole: (state) => state.common.userRole,
      selectedTenant: (state) => state.common.selectedTenant,
      isMultilingualSupportEnabled: (state) => state.common.isMultilingualSupportEnabled,
    }),
  },
  components: {
    InheritText: () => import('../../common/InheritText.vue'),
  },
  created() {
    this.getNewsCategories()
  },
  methods: {
    getNewsCategories() {
      if (this.selectedTenant.length) {
        this.$store
          .dispatch('widgets/getNewsCategories', this.isEditPage ? (this.userRole === 'SysAdmin' ? this.tenantId : null) : null)
          .then((response) => {
            response.data.forEach((data) => {
              const newsCategory = this.data.categories.find((item) => item.categoryId === data.categoryId)
              data.isSelected = newsCategory ? newsCategory.isSelected : data.isSelected
            })
            this.data.categories = [...response.data]
          })
          .catch(() => {
            this.data.categories = []
          })
      }
    },
    validateNewsCategories() {
      return this.data.categories.filter((category) => category.isSelected === true).length === 0
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      if (!this.isEditPage) {
        this.data.categories = []
        this.getNewsCategories()
      }
    },
  },
}
</script>
