var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-row',[_c('b-col',{attrs:{"sm":"12","md":"10","lg":"10","xl":"8"}},[_c('b-form-group',[_c('h4',{staticClass:"page-sub-header"},[_vm._v(" News categories "),(_vm.multilingualToggle)?_c('InheritText'):_vm._e()],1),_c('b-form-row',_vm._l((_vm.data.categories),function(option,index){return _c('b-col',{key:index,attrs:{"cols":"12","sm":"4"}},[_c('b-form-checkbox',{attrs:{"checked":option.isSelected,"disabled":_vm.multilingualToggle,"v-modal":option.isSelected,"id":'news-category-' + index,"name":'news-category-' + index,"inline":""},on:{"input":function($event){option.isSelected = $event}}},[_vm._v(_vm._s(option.categoryName))])],1)}),1),(_vm.validateNewsCategories() && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Atleast one category required.")]):_vm._e()],1)],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"10","md":"6","lg":"5","xl":"4"}},[_c('b-form-group',[_c('div',{staticClass:"floating-select-field"},[_c('b-form-select',{staticClass:"form-control",class:{
              'is-value-exist': _vm.data.dashboardItemCnt != null,
              'is-invalid': !_vm.data.dashboardItemCnt && _vm.formSubmitted,
            },attrs:{"id":"dashboard-items","options":_vm.widgetDashboardCountList,"disabled":_vm.multilingualToggle},on:{"input":function($event){_vm.data.dashboardItemCnt = $event}},model:{value:(_vm.data.dashboardItemCnt),callback:function ($$v) {_vm.$set(_vm.data, "dashboardItemCnt", _vm._n($$v))},expression:"data.dashboardItemCnt"}}),_c('label',{attrs:{"for":"dashboard-items"}},[_vm._v("Dashboard items "),(_vm.multilingualToggle)?_c('InheritText'):_vm._e()],1),(!_vm.data.dashboardItemCnt && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Dashboard items required.")]):_vm._e()],1)])],1),_c('b-col',{staticClass:"pt-md-2 mt-md-1",attrs:{"sm":"12","md":"6","lg":"4"}},[_vm._v(" Recommended: 3 entries")])],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"10","md":"6","lg":"5","xl":"4"}},[_c('b-form-group',[_c('div',{staticClass:"floating-select-field"},[_c('b-form-select',{staticClass:"form-control",class:{
              'is-value-exist': _vm.data.expViewItemCnt != null,
              'is-invalid': !_vm.data.expViewItemCnt && _vm.formSubmitted,
            },attrs:{"id":"dashboard-expanded-view-items","options":_vm.widgetDashboardCountList,"disabled":_vm.multilingualToggle},on:{"input":function($event){_vm.data.expViewItemCnt = $event}},model:{value:(_vm.data.expViewItemCnt),callback:function ($$v) {_vm.$set(_vm.data, "expViewItemCnt", _vm._n($$v))},expression:"data.expViewItemCnt"}}),_c('label',{attrs:{"for":"dashboard-expanded-view-items"}},[_vm._v("Expanded view items "),(_vm.multilingualToggle)?_c('InheritText'):_vm._e()],1),(!_vm.data.expViewItemCnt && _vm.formSubmitted)?_c('b-form-invalid-feedback',{staticClass:"d-block"},[_vm._v("Expanded view items required.")]):_vm._e()],1)])],1),_c('b-col',{staticClass:"pt-md-2 mt-md-1",attrs:{"sm":"12","md":"6","lg":"4"}},[_vm._v(" Recommended: 5 entries")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }